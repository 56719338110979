import { Rule } from 'models/rules';
import { dateUtils } from 'utils/time';

import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';
import { useDeleteBlacklistMutation } from 'service/blacklist';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { useOrgId } from 'hooks/useOrgId';
import { SuccessToast } from 'components/Toasts/SuccessToast';

import { DeleteAlert } from './DeleteAlert';

export function BlockRuleRow({ entity, createdAt, addedBy, comment, listType, entityId }: Rule) {
  const [deleteBlacklist] = useDeleteBlacklistMutation();

  const { showToast } = useToast();

  const [orgId] = useOrgId();
  const handleConfirm = async () => {
    try {
      await deleteBlacklist({
        orgId,
        listId: entityId,
        type: listType,
      }).unwrap();

      showToast({
        component: <SuccessToast message="Successfully deleted the rule" />,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Unable to delete rule. Please try again." />,
      });
    }
  };

  return (
    <tr className="border-b-light align-middle border-border-primary">
      <td className="py-6 px-3 text-sm text-black">{entity}</td>
      <td className="text-start py-6 px-3 text-black text-sm">
        <div>{dateUtils.format(createdAt, 'DD/MM/YYYY')?.toString()}</div>
        <div className="text-light-grey">{dateUtils?.format(createdAt, 'hh:mm A')?.toString()}</div>
      </td>
      <td className="text-start py-6 px-3 text-black text-sm">
        <div className="flex items-center gap-2">
          <Avatar
            customCssClass="w-6 h-6 text-xs"
            bgColor="soft-blue"
            textColor="avatar-blue"
            initials={getInitials(addedBy.name !== '' ? addedBy.name : addedBy.email, 2)}
          />
          <span>{addedBy.name !== '' ? addedBy.name : addedBy.email}</span>
        </div>
      </td>
      <td className="text-start py-6 px-3 text-black text-sm">{comment}</td>
      <td className="py-6 px-3 text-center">
        <DeleteAlert onConfirm={handleConfirm} />
      </td>
    </tr>
  );
}
