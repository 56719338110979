import { ReactComponent as IconAlert } from 'assets/icons/alert-rules.svg';

interface EmptyRuleProps {
  onAddRule: () => void;
}
export function EmptyRule({ onAddRule }: EmptyRuleProps) {
  return (
    <div className="w-full py-12 flex flex-col justify-center items-center gap-6 bg-white rounded-lg">
      <IconAlert />
      <span className="text-xl">Nothing to show here</span>
      <span className="text-sm text-center text-light-grey">Get started by adding a new rule</span>
      <button
        type="button"
        className="px-4 py-2 text-white bg-black rounded-md text-sm"
        onClick={() => onAddRule()}
      >
        Add a Block Rule +
      </button>
    </div>
  );
}
