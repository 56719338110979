import { createApi } from '@reduxjs/toolkit/query/react';
import { ALLOWEDLIST_URL, DELETE_ALLOWEDLIST_URL } from 'constants/apiUrls';
import { ListResults, ListFilter, ListReq, RuleFilter } from 'models/rules';
import { baseQuery } from './baseQuery';

export const allowedlistApi = createApi({
  reducerPath: 'allowedlistApi',
  baseQuery: baseQuery,
  tagTypes: ['ALLOWEDLIST'],
  endpoints: (builder) => ({
    getAllowedList: builder.query<ListResults, ListFilter>({
      query: ({ orgId, type, limit, offset }) => {
        const params = new URLSearchParams();

        if (limit) params.append('limit', limit.toString());
        if (offset) params.append('offset', offset.toString());

        if (type) params.append('type', type);

        return `${ALLOWEDLIST_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['ALLOWEDLIST'],
    }),
    createAllowedList: builder.mutation<undefined, ListReq & { orgId: string }>({
      query: ({ orgId, listEntries }) => {
        return {
          url: `${ALLOWEDLIST_URL(orgId)}`,
          body: {
            listEntries,
          },
          method: 'POST',
        };
      },
      invalidatesTags: ['ALLOWEDLIST'],
    }),
    deleteAllowedList: builder.mutation<
      undefined,
      { orgId: string; listId: string; type: RuleFilter }
    >({
      query: ({ orgId, listId, type }) => ({
        url: `${DELETE_ALLOWEDLIST_URL(orgId, listId)}?type=${type}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ALLOWEDLIST'],
    }),
  }),
});

export const {
  useGetAllowedListQuery,
  useCreateAllowedListMutation,
  useDeleteAllowedListMutation,
} = allowedlistApi;
