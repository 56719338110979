export function validateDomain(domain: string): boolean {
  const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
}

export type ValidationResult = {
  isValid: boolean;
  error: string;
};

// IPv4 validation
export function validateIPv4(input: string): ValidationResult {
  const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
  const trimmedInput = input.trim();

  if (!ipv4Pattern.test(trimmedInput)) {
    return { isValid: false, error: 'Invalid IPv4 format' };
  }

  const octets = trimmedInput.split('.');
  const validOctets = octets.every((octet) => {
    const num = parseInt(octet, 10);
    return num >= 0 && num <= 255;
  });

  return validOctets
    ? { isValid: true, error: '' }
    : { isValid: false, error: 'Invalid IPv4 octet values' };
}

// Email validation
export function validateEmail(input: string): ValidationResult {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const trimmedInput = input.trim();

  if (!emailPattern.test(trimmedInput)) {
    return { isValid: false, error: 'Invalid email format' };
  }

  const [localPart, domain] = trimmedInput.split('@');
  if (localPart.length > 64 || domain.length > 255) {
    return { isValid: false, error: 'Email part exceeds length limit' };
  }

  return { isValid: true, error: '' };
}

// Domain validation
export function validateDomains(input: string): ValidationResult {
  const domainPattern = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  const trimmedInput = input.trim();

  if (!domainPattern.test(trimmedInput)) {
    return { isValid: false, error: 'Invalid domain format' };
  }

  if (trimmedInput.length > 255) {
    return { isValid: false, error: 'Domain name too long' };
  }

  const hasValidParts = trimmedInput.split('.').every((part) => part.length <= 63);
  if (!hasValidParts) {
    return { isValid: false, error: 'Domain part exceeds length limit' };
  }

  return { isValid: true, error: '' };
}
