import { ReactComponent as Spy } from 'assets/icons/icon-spy-black.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Dollar } from 'assets/icons/dollar.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/icon-location-error.svg';
import { ReactComponent as WorldIcon } from 'assets/icons/icon-internet-error.svg';
import { ReactComponent as DomainAlt } from 'assets/icons/domain-alt.svg';
import { ReactComponent as Bomb } from 'assets/icons/bomb.svg';
import { ReactComponent as Urgency } from 'assets/icons/urgent.svg';
import { ReactElement } from 'react';
import { Event } from 'models/events';
import { AnalysisType } from 'enums/eventStatusTypes';

type ThreatDetails = {
  text: string;
  icon: ReactElement;
  textColor: string;
  bgColor: string;
  borderColor: string;
};

export function getThreatDetailsByCode(code: string) {
  const allThreats: Record<string, ThreatDetails> = {
    vip_impersonation: {
      text: 'VIP Impersonation',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    qr_scam: {
      text: 'QR Scam',
      icon: <Dollar className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    vendor_impersonation: {
      text: 'Vendor Impersonation',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    regulator_impersonation: {
      text: 'Regulator Impersonation',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    malware: {
      text: 'Malware',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    bec: {
      text: 'BEC',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    financial_fraud: {
      text: 'Financial Fraud',
      icon: <Dollar className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    attorney_fraud: {
      text: 'Attorney Fraud',
      icon: <Dollar className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    invoice_fraud: {
      text: 'Invoice Fraud',
      icon: <Dollar className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    insider_threat: {
      text: 'Insider Threat',
      icon: <Dollar className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    invoice_anomaly: {
      text: 'Invoice Anomaly',
      icon: <Dollar className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
  };

  return (
    allThreats[code] || {
      text: code,
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'avatar-blue',
      bgColor: 'soft-blue',
      borderColor: 'soft-blue',
    }
  );
}

export function getInferenceDetailsByCode(code: string) {
  const allInferences: Record<string, ThreatDetails> = {
    vip_impersonation: {
      text: 'VIP Impersonation',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    qr_scam: {
      text: 'QR Scam',
      icon: <Dollar className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    suspicious_domain: {
      text: 'Suspicious Domain',
      icon: <WorldIcon className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    financial_request_intent: {
      text: 'Financial Request Intent',
      icon: <Dollar className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    display_name_vip: {
      text: 'Display Name VIP',
      icon: <Spy className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    frequent_sender: {
      text: 'Frequent Sender',
      icon: <Bomb className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    request_for_action_intent: {
      text: 'Action Required',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    sense_of_urgency_tone: {
      text: 'Urgency Detected',
      icon: <Urgency className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    location_anomaly: {
      text: 'Location Anomaly',
      icon: <LocationIcon className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    first_time_sender: {
      text: 'First Time Sender',
      icon: <Spy className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    vendor_domain_lookalike: {
      text: 'Vendor Domain Lookalike',
      icon: <DomainAlt className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    regulator_domain_lookalike: {
      text: 'Regulator Domain Lookalike',
      icon: <DomainAlt className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    malicious_url: {
      text: 'Malicious URL',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    malicious_attachment: {
      text: 'Malicious Attachment',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    recurring_invoice: {
      text: 'Recurring Invoice',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    is_recipient_finance_team: {
      text: 'Finance Team',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    invoice_bank_details_anomaly: {
      text: 'Invoice Bank Details Anomaly',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    legal_intent: {
      text: 'Legal Intent',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
  };

  return (
    allInferences[code] || {
      text: code,
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'avatar-blue',
      bgColor: 'soft-blue',
      borderColor: 'soft-blue',
    }
  );
}

// getInferences returns the inferences for the given key

export function getInferences(key: string, event: Event): string[] {
  const keyVector = event.vectors?.find((vector) => vector?.code === key);

  // If vector is found and has inferences, return them; otherwise, return an empty array.
  return keyVector?.inferences || [];
}

// inferenceToProductMap
export const inferenceToProductMap: Record<string, AnalysisType> = {
  suspicious_domain: 'detection',
  financial_request_intent: 'observation',
  display_name_vip: 'observation',
  first_time_sender: 'observation',
  frequent_sender: 'observation',
  request_for_action_intent: 'observation',
  sense_of_urgency: 'observation',
  sense_of_urgency_tone: 'observation',
  regulator_domain_lookalike: 'detection',
  vendor_domain_lookalike: 'detection',
  malicious_url: 'detection',
  malicious_attachment: 'detection',
  is_recipient_finance_team: 'observation',
  location_anomaly: 'detection',
  display_name_impersonation: 'detection',
  sent_time_anomaly: 'detection',
  content_sender_mismatch: 'tone',
  recurring_invoice: 'observation',
  invoice_bank_details_anomaly: 'observation',
};

export const getBadges = (event: Event): React.ReactNode[] => {
  const badges: React.ReactNode[] = [];
  if (event?.threats?.length) {
    event?.threats?.forEach((threat) => {
      const badgeDetails = getThreatDetailsByCode(threat.code);
      badges.push(
        <button
          type="button"
          className="px-2 py-0.5 rounded-lg border border-soft-red-border bg-soft-red text-black text-sm flex items-center gap-1"
        >
          <Spy className="w-2.5 h-2.5 fill-error-text" />
          {badgeDetails.text}
        </button>
      );
    });
  } else {
    event.detections.forEach((threat) => {
      const badgeDetails = getThreatDetailsByCode(threat);
      badges.push(
        <button
          type="button"
          className="px-2 py-0.5 rounded-lg border border-soft-red-border bg-soft-red text-black  text-sm flex items-center gap-1"
        >
          <Spy className="w-2.5 h-2.5 fill-error-text" />
          {badgeDetails.text}
        </button>
      );
    });
  }
  return badges;
};

export const getRecipientDisplayName = (event: Event) => {
  const allRecipientEmails = event?.recipients
    ?.map((recipient) => ({ name: recipient?.name, email: recipient?.email }))
    ?.concat(event?.cc)
    ?.concat(event?.bcc)
    ?.concat(event.webhookRecipients)
    ?.map((r) => r?.email?.toLowerCase())
    ?.filter((r) => !!r);

  const uniqueRecipients = new Set(allRecipientEmails);
  const recipientCount = uniqueRecipients.size;

  if (recipientCount) {
    return recipientCount > 1
      ? `${recipientCount} Recipients`
      : uniqueRecipients.values().next().value;
  }

  return 'No Recipients';
};
