import { ReactComponent as IconWarning } from 'assets/icons/icon-warning-alert.svg';

interface ErrorToastProps {
  message: string;
}

export function ErrorToast({ message }: ErrorToastProps) {
  return (
    <div className="flex items-center p-2 gap-2 bg-white rounded-lg shadow-md">
      <IconWarning className="fill-red-600" />
      <p className="text-sm ">{message}</p>
    </div>
  );
}
