import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';

function RootLayout() {
  return (
    <div className="w-screen h-screen flex">
      <Sidebar />
      <div className="flex-1 overflow-auto bg-white">
        <Outlet />
      </div>
    </div>
  );
}

export default RootLayout;
