import { useState } from 'react';

import { Badge } from 'components/Badge';
import { getBadges, getInferences, getRecipientDisplayName } from 'components/Events/data';
import { Alert } from 'components/Alert';

import { ReactComponent as IconUndoRemediation } from 'assets/icons/undo-remediation.svg';

import { useUndoRemediationMutation } from 'service/eventsApi';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';
import { useAuth } from 'contexts/AuthContext';

import { Event } from 'models/events';
import { dateUtils } from 'utils/time';
import { UNDO_MOVE_TO_JUNK, UNDO_QUARANTINE_MESSAGE } from 'constants/remediationActions';

import { RemediateOverview } from './RemediateOverview';
import { UndoRemediationAlert } from './UndoRemediation';
import { remediationActionDetails } from './data';

interface RemediationRowProps {
  event: Event;
  quarantinedBy: string;
  quarantinedDate: string;
  action: string;
}

export function RemediationRow({
  event,
  quarantinedBy,
  quarantinedDate,
  action,
}: RemediationRowProps) {
  const { showToast } = useToast();

  const { email } = useAuth();

  const [openOverview, setOpenOverview] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);

  const handleAlertOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAlertOpen(true);
  };

  const [undoRemediation] = useUndoRemediationMutation();

  const [OrgId] = useOrgId();

  const handleConfirm = async () => {
    // avoid multiple api calls
    if (loading) return;

    setLoading(true);

    try {
      await undoRemediation({
        orgId: OrgId,
        messageId: event.messageId,
        action: action === 'MOVE_TO_JUNK' ? UNDO_MOVE_TO_JUNK : UNDO_QUARANTINE_MESSAGE,
      }).unwrap();

      showToast({
        component: (
          <ActionToast
            description="Moved message to 'Inbox'"
            showUndo={false}
            initials={getInitials(email, 1)}
          />
        ),
      });
    } catch {
      showToast({
        component: (
          <ErrorToast message='Failed to move message to "Inbox", please try again later' />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <tr className="border-b align-top cursor-pointer" onClick={() => setOpenOverview(true)}>
        <td title={event.subject} className="text-start px-6 py-3">
          <p className="text-black text-xs max-w-full text-ellipsis line-clamp-2">
            {event.subject}
          </p>
        </td>
        <td className="text-start px-6 py-3">
          <p
            title={event.sender.email}
            className={`w-fit text-left px-2 py-0.5 text-xs text-black ${getInferences('sender', event).length ? 'bg-soft-red border-soft-red-border border-light rounded-sm' : ''} break-words max-w-full text-ellipsis line-clamp-1`}
          >
            {event.sender.email}
          </p>
        </td>
        <td className="text-start px-6 py-3">
          <p
            title={getRecipientDisplayName(event)}
            className={`w-fit text-left px-2 py-0.5 text-xs text-black ${getInferences('recipients', event).length ? 'bg-soft-red border-soft-red-border border-light rounded-sm' : ''} break-words max-w-full text-ellipsis line-clamp-1`}
          >
            {getRecipientDisplayName(event)}
          </p>
        </td>
        <td className="text-start px-4 py-3 text-black text-xs flex flex-wrap gap-2">
          {getBadges(event)}
        </td>
        <td title={quarantinedBy} className="text-start px-4 py-3 text-black text-xs break-words">
          <p title={quarantinedBy} className="max-w-full text-ellipsis line-clamp-1">
            {quarantinedBy}
          </p>
        </td>
        <td className="text-start px-4 py-3 text-black text-xs">
          <div>{dateUtils.format(event?.time, 'DD/MM/YYYY')?.toString()}</div>
          <div className="text-light-grey">
            {dateUtils?.format(event.time, 'hh:mm A')?.toString()}
          </div>
        </td>
        <td className="text-start px-4 py-3 text-black text-xs">
          <div>{dateUtils.format(quarantinedDate, 'DD/MM/YYYY')?.toString()}</div>
          <div className="text-light-grey">
            {dateUtils?.format(quarantinedDate, 'hh:mm A')?.toString()}
          </div>
        </td>
        <td className="text-start px-4 py-3 text-black text-xs flex flex-wrap gap-2">
          <Badge
            key={action}
            text={remediationActionDetails[action].text}
            bgColor={event.status === 'Remediated' ? 'soft-green' : 'soft-red'}
            borderColor={event.status === 'Remediated' ? 'soft-green-border' : 'soft-red-border'}
            textColor={event.status === 'Remediated' ? 'success-green' : 'error-text'}
            borderRadius="rounded-lg"
            textCase="capitalize"
          />
        </td>
        <td className="text-start px-4 py-3 text-xs">
          <button
            type="button"
            className={`bg-${event.status === 'Remediated' ? 'action-bg' : 'success-green'} px-3 py-1.5 rounded-lg text-white`}
            onClick={handleAlertOpen}
          >
            {event.status === 'Remediated' ? 'Undo Remediation' : 'Reverted'}
          </button>
        </td>
      </tr>
      <RemediateOverview
        event={event}
        open={openOverview}
        onClose={() => setOpenOverview(false)}
        action={action}
      />
      <Alert
        open={alertOpen}
        cancelText="Cancel"
        confirmText="Confirm"
        content={<UndoRemediationAlert />}
        onConfirm={() => handleConfirm()}
        onCancel={() => setAlertOpen(false)}
        message="Are you sure you want to undo the remediation actions taken for this email? The following actions will be performed"
        icon={<IconUndoRemediation />}
        loading={loading}
      />
    </>
  );
}
