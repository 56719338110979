import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { dateUtils } from 'utils/time';

import { useOrgId } from 'hooks/useOrgId';
import { useEffect, useState } from 'react';
import { useGetThreatFrequencyQuery } from 'service/threatFrequency';
import { FrequencyGraphToolTip } from 'components/FrequencyGraph/FrequencyGraphToolTip';
import {
  ThreatFrequencyResponse,
  ThreatFrequencyFilters,
  ThreatType,
} from 'models/threatInferences';
import { TransformToTimeseries } from 'components/FrequencyGraph/transformations';
import { TabCategory } from 'enums/threatCategory';
import Loader from 'components/Loader';

interface ThreatFrequencyProps {
  tab: TabCategory;
  from: string;
  to: string;
}

interface GraphLabel {
  label: string;
  value: string;
  markerColor: string;
  stroke: string;
}

export function Frequency({ tab, from, to }: ThreatFrequencyProps) {
  const [OrgId] = useOrgId();

  const getThreatFilter = () => {
    switch (tab) {
      case 'impersonation':
        return `${ThreatType.VIPImpersonation},${ThreatType.VENDORImpersonation},${ThreatType.REGULATORImpersonation}`;
      case 'bec':
        return `${ThreatType.BEC},${ThreatType.FINANCIAL_FRAUD},${ThreatType.ATTORNEY_FRAUD},${ThreatType.INVOICE_FRAUD}`;
      case 'insider_threat':
        return ThreatType.INSIDER_THREAT;
      case 'malware':
        return ThreatType.MALWARE;
      default:
        return '';
    }
  };

  const [filters, setFilters] = useState<ThreatFrequencyFilters>({
    orgId: OrgId,
    from: from,
    to: to,
    frequency: 'daily',
    threat: getThreatFilter(),
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      from,
      to,
    }));
  }, [from, to]);

  const { data, isLoading } = useGetThreatFrequencyQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  function getDataByThreat(
    threatName: ThreatType,
    threatData: ThreatFrequencyResponse | undefined
  ) {
    if (!threatData) return [];
    return (
      threatData?.threats?.find((threat) => threat.code?.toUpperCase() === threatName)?.frequency ||
      []
    );
  }

  const getTransformedDataByThreat = (threat: ThreatType) => {
    return TransformToTimeseries(
      filters.from || '',
      filters.to || '',
      (data && getDataByThreat(threat, data)) || []
    );
  };

  const getImpersonationThreatData = () => {
    const vipImpersonationData = getTransformedDataByThreat(ThreatType.VIPImpersonation);
    const vendorImpersonationData = getTransformedDataByThreat(ThreatType.VENDORImpersonation);
    const regulatorImpersonationData = getTransformedDataByThreat(
      ThreatType.REGULATORImpersonation
    );

    const allFrequencyData = [];

    for (let i = 0; i < vipImpersonationData.length; i += 1) {
      const eachFrequency = {
        date: vipImpersonationData[i].date,
        vipImpersonation: vipImpersonationData[i].count,
        vendorImpersonation: vendorImpersonationData[i].count,
        regulatorImpersonation: regulatorImpersonationData[i].count,
      };

      allFrequencyData.push(eachFrequency);
    }

    return allFrequencyData;
  };

  const getBECThreatData = () => {
    const financialFraudData = getTransformedDataByThreat(ThreatType.FINANCIAL_FRAUD);
    const attorneyFraudData = getTransformedDataByThreat(ThreatType.ATTORNEY_FRAUD);
    const invoiceFraudData = getTransformedDataByThreat(ThreatType.INVOICE_FRAUD);

    const allFrequencyData = [];

    for (let i = 0; i < financialFraudData.length; i += 1) {
      const eachFrequency = {
        date: financialFraudData[i].date,
        financialFraud: financialFraudData[i].count,
        attorneyFraud: attorneyFraudData[i].count,
        invoiceFraud: invoiceFraudData[i].count,
      };

      allFrequencyData.push(eachFrequency);
    }

    return allFrequencyData;
  };

  const getMalwareData = () => {
    const malwareData = getTransformedDataByThreat(ThreatType.MALWARE);

    const allFrequencyData = [];

    for (let i = 0; i < malwareData.length; i += 1) {
      const eachFrequency = {
        date: malwareData[i].date,
        malware: malwareData[i].count,
      };

      allFrequencyData.push(eachFrequency);
    }

    return allFrequencyData;
  };

  const getInsiderThreatData = () => {
    const insiderThreatData = getTransformedDataByThreat(ThreatType.INSIDER_THREAT);

    const allFrequencyData = [];

    for (let i = 0; i < insiderThreatData.length; i += 1) {
      const eachFrequency = {
        date: insiderThreatData[i].date,
        insiderThreat: insiderThreatData[i].count,
      };

      allFrequencyData.push(eachFrequency);
    }

    return allFrequencyData;
  };

  const getAllData = () => {
    // impersonation data
    const vipImpersonationData = getTransformedDataByThreat(ThreatType.VIPImpersonation);
    const vendorImpersonationData = getTransformedDataByThreat(ThreatType.VENDORImpersonation);
    const regulatorImpersonationData = getTransformedDataByThreat(
      ThreatType.REGULATORImpersonation
    );

    // bec data
    const financialFraudData = getTransformedDataByThreat(ThreatType.FINANCIAL_FRAUD);
    const attorneyFraudData = getTransformedDataByThreat(ThreatType.ATTORNEY_FRAUD);
    const invoiceFraudData = getTransformedDataByThreat(ThreatType.INVOICE_FRAUD);

    // malware data
    const malwareData = getTransformedDataByThreat(ThreatType.MALWARE);

    // insider threat data
    const insiderThreatData = getTransformedDataByThreat(ThreatType.INSIDER_THREAT);

    // all frequency data
    const allFrequencyData = [];

    for (let i = 0; i < vipImpersonationData.length; i += 1) {
      const eachFrequency = {
        date: vipImpersonationData[i].date,
        vipImpersonation: vipImpersonationData[i].count,
        vendorImpersonation: vendorImpersonationData[i].count,
        regulatorImpersonation: regulatorImpersonationData[i].count,
        financialFraud: financialFraudData[i].count,
        attorneyFraud: attorneyFraudData[i].count,
        invoiceFraud: invoiceFraudData[i].count,
        malware: malwareData[i].count,
        insiderThreat: insiderThreatData[i].count,
      };

      allFrequencyData.push(eachFrequency);
    }

    return allFrequencyData;
  };

  const getGraphData = () => {
    if (isLoading) return [];

    switch (tab) {
      case 'impersonation':
        return getImpersonationThreatData();
      case 'bec':
        return getBECThreatData();
      case 'malware':
        return getMalwareData();
      case 'insider_threat':
        return getInsiderThreatData();
      case 'all':
        return getAllData();
      default:
        return [];
    }
  };

  const graphLabels: Record<string, GraphLabel[]> = {
    impersonation: [
      {
        label: 'VIP Impersonation',
        value: 'vipImpersonation',
        markerColor: 'light-blue',
        stroke: '#F7C6FF',
      },
      {
        label: 'Vendor Impersonation',
        value: 'vendorImpersonation',
        markerColor: 'light-green',
        stroke: '#D173E0',
      },
      {
        label: 'Regulator Impersonation',
        value: 'regulatorImpersonation',
        markerColor: 'light-pink',
        stroke: '#AC38BF',
      },
    ],
    malware: [
      {
        label: 'Malware',
        value: 'malware',
        markerColor: 'light-blue',
        stroke: '#FFB098',
      },
    ],
    bec: [
      {
        label: 'Financial Fraud',
        value: 'financialFraud',
        markerColor: 'light-blue',
        stroke: '#198ABF',
      },
      {
        label: 'Attorney Fraud',
        value: 'attorneyFraud',
        markerColor: 'light-green',
        stroke: '#62C6F5',
      },
      {
        label: 'Invoice Fraud',
        value: 'invoiceFraud',
        markerColor: 'light-pink',
        stroke: '#BDEAFF',
      },
    ],
    insider_threat: [
      {
        label: 'Insider Threat',
        value: 'insiderThreat',
        markerColor: 'light-blue',
        stroke: '#FFE03B',
      },
    ],
  };

  const getGraphLabel = () => {
    if (tab === 'all') {
      const labels: GraphLabel[] = [];
      Object.keys(graphLabels).forEach((key) => {
        labels.push(...graphLabels[key]);
      });

      return labels;
    }

    return graphLabels[tab];
  };

  return (
    <ResponsiveContainer width="100%" height={240} className="mt-6">
      {isLoading ? (
        <Loader />
      ) : (
        <LineChart
          accessibilityLayer
          margin={{
            left: 20,
            right: 20,
          }}
          data={getGraphData()}
        >
          <XAxis
            dataKey="date"
            className="text-xs"
            color="#E1E5E8"
            axisLine={false}
            tickLine={false}
            tick={{
              fill: '#9FAFB5',
              fontSize: '10px',
            }}
            tickMargin={12}
            tickFormatter={(value) => {
              const dateText = value as string;
              return dateUtils.format(dateText, 'MMM DD').toString();
            }}
            scale="point"
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            orientation="right"
            tick={{
              fill: '#9FAFB5',
              fontSize: '10px',
            }}
            tickMargin={16}
            tickCount={3}
            domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.2)]}
          />
          <CartesianGrid
            vertical={false}
            color="#E1E5E8"
            strokeWidth={0.5}
            strokeOpacity={0.5}
            horizontalCoordinatesGenerator={({ height }) => {
              const count = 10;
              return Array(count)
                .fill(0)
                .map((_, index) => height * (index / count));
            }}
          />
          <Tooltip
            content={<FrequencyGraphToolTip labels={getGraphLabel() || []} />}
            cursor={false}
          />
          {getGraphLabel()?.map((eachLegend) => (
            <Line
              dataKey={eachLegend.value}
              stroke={eachLegend.stroke}
              dot={false}
              strokeWidth={1}
            />
          ))}
        </LineChart>
      )}
    </ResponsiveContainer>
  );
}
