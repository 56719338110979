import * as Popover from '@radix-ui/react-popover';
import * as Checkbox from '@radix-ui/react-checkbox';

import { ReactComponent as IconCheck } from 'assets/icons/checkbox-icon.svg';
import { ReactComponent as IconFilter } from 'assets/icons/filter.svg';
import { ReactComponent as IconChevronDown } from 'assets/icons/icon-chevron-down.svg';
import { ReactComponent as IconSearch } from 'assets/icons/magnifying-glass.svg';

import { Divider } from 'components/Divider';
import Loader from 'components/Loader';
import { useOrgId } from 'hooks/useOrgId';
import { useEffect, useState } from 'react';
import { useGetThreatStatsQuery } from 'service/threats';
import { getThreatDetailsByCode } from './data';

export type EventStatusFilter = 'pending' | 'remediated' | 'all';

export interface EventFilterOptions {
  threats?: string[];
  status?: EventStatusFilter;
}

interface EventFilter {
  selectedThreats: string[];
  from: string;
  to: string;
  onApply: (filters: EventFilterOptions) => void;
}

export function EventFilter({ selectedThreats, from, to, onApply }: EventFilter) {
  const [open, setOpen] = useState<boolean>(false);

  const [selectedFilter, setSelectedFilter] = useState<string>('');

  const [showSubFilters, setShowSubFilters] = useState<boolean>(false);

  const [threats, setThreats] = useState<string[]>(selectedThreats);

  const [allThreats, setAllThreats] = useState<string[]>([]);

  const handleSelectThreat = () => {
    setSelectedFilter('Threat');
    setShowSubFilters(true);
  };

  const [OrgId] = useOrgId();

  const { data, isLoading } = useGetThreatStatsQuery(
    {
      orgId: OrgId,
      threatCategory: '',
      from,
      to,
    },
    {
      skip: !showSubFilters,
    }
  );

  useEffect(() => {
    if (data) {
      setAllThreats(data?.threats?.map((threat) => threat.code) || []);
    }
  }, [data]);

  const handleToggleThreat = (threat: string) => {
    if (threats.includes(threat)) {
      setThreats(threats.filter((t) => t !== threat));
    } else {
      setThreats([...threats, threat]);
    }
  };

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearch = (query: string) => {
    setSearchQuery(query);

    setAllThreats(
      data?.threats
        .filter((threat) => threat.code.toLowerCase().includes(query.toLowerCase()))
        ?.map((threat) => threat.code) || []
    );
  };

  const handleApplyFilters = () => {
    onApply({ threats });
    setOpen(false);
  };

  useEffect(() => {
    setThreats(selectedThreats);
  }, [selectedThreats]);

  const handleClearAll = () => {
    onApply({ threats: [] });
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <button
          className="rounded-lg border-light border-border-primary flex items-center justify-between w-fit gap-4 px-2.5 py-1.5"
          type="button"
        >
          <IconFilter className="w-3 h-3" />
          <span className="text-sm text-black">Filters</span>
          <IconChevronDown className="w-3 h-3" />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content sideOffset={5} side="bottom" align="start">
          <div className="flex items-start gap-2">
            <div className="w-96 py-5 px-3 bg-white rounded-lg shadow-md border">
              <div className="w-full flex items-center justify-between px-3">
                <span className="text-xs">Add Filters</span>
                <button
                  type="button"
                  className="text-xs border-none text-avatar-blue"
                  onClick={() => handleClearAll()}
                >
                  Clear All
                </button>
              </div>
              <div className="flex flex-col gap-4 mt-4">
                <button
                  className={`${selectedFilter === 'Threat' ? 'bg-soft-gray border border-border-primary' : 'bg-white'} rounded-lg p-3 flex items-center justify-between`}
                  onClick={() => handleSelectThreat()}
                  type="button"
                >
                  <span className="text-sm">Threat</span>
                  <IconChevronDown className="-rotate-90 w-3 h-3" />
                </button>
                {/* <button className={`${selectedFilter === 'severity' ? "bg-soft-gray border border-border-primary" : "bg-white"} rounded-lg py-3 px-4`}>
                <span>Severity</span>
                <IconChevronDown className="-rotate-90 w-4 h-4" />
              </button>
              */}
              </div>
              <Divider />
              <button
                type="button"
                className="w-full text-xs rounded-full bg-action-bg py-3 text-white"
                onClick={() => handleApplyFilters()}
              >
                Apply Filter
              </button>
            </div>
            {showSubFilters && selectedFilter === 'Threat' && (
              <div className="w-72 py-5 px-3 bg-white rounded-lg shadow-md border flex flex-col gap-4">
                <span className="text-xs">Threat</span>
                <div className="flex items-center justify-between border-border-primary bg-soft-gray rounded-lg py-2 px-3 gap-2">
                  <input
                    className="border-none outline-none flex-1 bg-transparent text-xs"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchQuery}
                  />
                  <IconSearch className="w-3 h-3" />
                </div>
                <div className="flex flex-col gap-2">
                  {isLoading ? (
                    <div className="w-full flex justify-center items-center">
                      {' '}
                      <Loader />
                    </div>
                  ) : (
                    allThreats.map((threat) => {
                      const threatDetails = getThreatDetailsByCode(threat);
                      return (
                        <div className="w-full flex items-center gap-4">
                          <Checkbox.Root
                            className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                            checked={threats.includes(threat)}
                            onClick={() => handleToggleThreat(threat)}
                          >
                            <Checkbox.Indicator>
                              <IconCheck />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <span className="px-2 py-0.5 rounded-lg border border-soft-red-border bg-soft-red text-black text-sm text-center">
                            {threatDetails.text}
                          </span>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            )}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
