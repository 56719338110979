import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { useState } from 'react';
//

interface DeleteAlertProps {
  onConfirm: () => void;
}
export function DeleteAlert({ onConfirm }: DeleteAlertProps) {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger className="text-end" asChild>
        <button
          type="button"
          className="w-fit p-2 rounded-md flex gap-2 justify-center items-center border-light border-border-primary"
          onClick={() => setOpen(!open)}
        >
          {/* we are disabling this here, because we are showing icon and not a label */}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}

          <IconTrash className="w-3.5 h-3.5" />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <div className="w-full">
          <DropdownMenu.Content
            className="z-20 data-[state=open]:outline-none w-full animate-fade-in bg-white rounded-md flex flex-col text-xs p-2 shadow-custom"
            side="bottom"
            align="end"
          >
            <div className="flex flex-col items-center gap-4 px-4 py-2">
              <span className="text-light-grey text-sm w-2/3 text-center">
                Are you sure, you want to delete the rule?
              </span>
              <div className="flex items-center gap-2 w-full">
                <button
                  type="button"
                  className="bg-red-600 rounded-md px-2 py-1 text-white text-sm flex-1"
                  onClick={() => onConfirm()}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="px-2 py-1 border-light border-border-primary rounded-md text-sm flex-1"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </DropdownMenu.Content>
        </div>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
