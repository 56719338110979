import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Dialog from '@radix-ui/react-dialog';

import { Divider } from 'components/Divider';
import { ReactComponent as IconShieldSelected } from 'assets/icons/icon-shield-selected.svg';
import { ReactComponent as IconShield } from 'assets/icons/icon-shield.svg';
import { ReactComponent as IconMonitorSelected } from 'assets/icons/icon-monitor-selected.svg';
import { ReactComponent as IconMonitorMode } from 'assets/icons/monitor-mode.svg';
import { ReactComponent as IconPreventMode } from 'assets/icons/prevent-mode.svg';

interface RemediationSettingsProps {
  navigateToBlockList: () => void;
}
export function RemediationSettings({ navigateToBlockList }: RemediationSettingsProps) {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const handleModeChange = (option: number) => {
    if (option === selectedOption) return;
    setShowModal(true);
  };

  const statusOptions = [
    {
      title: 'Real-Time Alerts',
      value: 'Enabled',
    },
    {
      title: 'Mail Remediation',
      value: !selectedOption ? 'Automatic' : 'Manual',
    },
    {
      title: 'Block / Allow Rules',
      value: !selectedOption ? 'Enabled' : 'Disabled',
    },
  ];

  const quickLinks = [
    {
      title: 'Configure Allow/Block Rules',
      actionName: 'Configure',
      onClick: () => navigateToBlockList(),
    },
    {
      title: 'Remediated Mails',
      actionName: 'Review',
      onClick: () => {
        navigate('/remediation');
      },
    },
  ];

  const handleToggleModal = () => {
    setShowModal((prevModal) => !prevModal);
  };

  const handleConfirm = () => {
    setShowModal(false);
    setSelectedOption(selectedOption === 1 ? 0 : 1);
  };

  return (
    <>
      <div className="px-10 py-8 w-full">
        <h2 className="text-xl">Remediation Settings</h2>
        <Divider customCss="my-4" />
        <div className="border-light border-border-primary rounded-lg px-1.5 py-1 w-fit flex items-center gap-2 mt-4">
          <button
            type="button"
            className={`flex justify-center items-center rounded-md gap-2 ${selectedOption === 0 ? 'bg-gradient-to-r from-gradient-yellow to-gradient-green text-white' : ''} px-4 py-2`}
            onClick={() => handleModeChange(0)}
          >
            {selectedOption === 0 ? <IconShieldSelected /> : <IconShield />}
            <span>Prevent Mode</span>
          </button>
          <button
            type="button"
            className={`flex justify-center items-center rounded-md gap-2 ${selectedOption === 1 ? 'bg-gradient-to-r from-gradient-orange to-gradient-red text-white' : ''} px-4 py-2`}
            onClick={() => handleModeChange(1)}
          >
            {selectedOption === 1 ? (
              <IconMonitorSelected />
            ) : (
              <IconMonitorSelected className="fill-border-primary" />
            )}
            <span>Monitor Mode</span>
          </button>
        </div>
        <Divider customCss="my-4" />
        <div
          className={`w-3/5 ${selectedOption === 0 ? 'bg-gradient-to-b from-[#EAFFF7] to-soft-gray' : 'bg-gradient-to-b from-[#FFF2F0] to-soft-gray'} py-3.5 px-7 rounded-lg`}
        >
          <p className="text-sm text-light-grey">
            Ravenmail is currently in{' '}
            <strong className="text-black">
              {selectedOption === 0 ? 'Prevent Mode' : 'Monitor Mode'}
            </strong>
          </p>
          <p className="text-sm text-light-grey">
            {selectedOption === 0
              ? 'Threats will be automatically handled based on your configured rules. Block/Allow list rules are active.'
              : 'Threats will be flagged for manual review. Block/Allow list rules are inactive.'}
          </p>
        </div>
        <div className="mt-12">
          <span>Status Summary</span>
          <Divider />
          <div className="w-1/2 flex flex-col gap-6">
            {statusOptions.map((status) => (
              <div className="w-full flex items-center justify-between">
                <span className="font-medium">{status.title}</span>
                <button
                  type="button"
                  className={`min-w-24 ${['Automatic', 'Enabled'].includes(status.value) ? 'bg-soft-green' : 'bg-soft-gray'} px-2 py-1 rounded-md border-border-primary border-light`}
                >
                  {status.value}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-24">
          <span>Quick Links</span>
          <Divider customCss="my-6" />
          <div className="w-1/2 flex flex-col gap-6">
            {quickLinks.map((link) => (
              <div className="w-full flex items-center justify-between">
                <span className="font-medium">{link.title}</span>
                <button
                  type="button"
                  className="min-w-24 text-center px-2 py-1 bg-black text-white rounded-md"
                  onClick={() => link.onClick()}
                >
                  {link.actionName}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Dialog.Root open={showModal} onOpenChange={handleToggleModal}>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 z-20" onClick={handleToggleModal} />
        <Dialog.Content className="fixed top-1/2 left-1/2 flex flex-col justify-center items-center transform -translate-x-1/2 -translate-y-1/2 w-1/4 bg-white border-light border-border-primary rounded-md z-30">
          <div className="w-full flex flex-col items-center gap-6 py-6 px-10 border-b-light border-b-border-primary">
            {selectedOption === 0 ? (
              <IconMonitorMode className="w-24 h-24" />
            ) : (
              <IconPreventMode className="w-24 h-24" />
            )}
            <div className="flex flex-col gap-4 text-center">
              <h3 className="text-xl">
                Are you sure you want to change to {selectedOption === 0 ? 'Monitor' : 'Prevent'}{' '}
                Mode?
              </h3>
              <p className="text-light-grey">The following changes will take place</p>
            </div>
          </div>
          <div className="flex flex-col gap-6 w-full px-14 py-6">
            <div className="flex items-center justify-between">
              <span>Mail Remediation</span>
              <button
                type="button"
                className={`border-light border-border-primary rounded-md p-2 ${selectedOption === 0 ? 'bg-soft-gray' : 'bg-soft-green'}`}
              >
                {selectedOption === 0 ? 'Manual' : 'Automatic'}
              </button>
            </div>
            <div className="flex items-center justify-between">
              <span>Block / Allow Rules</span>
              <button
                type="button"
                className={`border-light border-border-primary rounded-md p-2 ${selectedOption === 0 ? 'bg-soft-gray' : 'bg-soft-green'}`}
              >
                {selectedOption === 0 ? 'Disabled' : 'Enabled'}
              </button>
            </div>
          </div>
          <Divider customCss="my-1 w-full" />
          <div className="py-6 px-10 w-full flex flex-col gap-2 items-center">
            <button
              type="button"
              className="w-full bg-black text-white text-center rounded-lg py-4"
              onClick={() => handleConfirm()}
            >
              Confirm
            </button>
            <button
              type="button"
              className="bg-white border-b-light border-light-grey text-light-grey"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
