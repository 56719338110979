import { ReactComponent as IconCheck } from 'assets/icons/check.svg';

interface SuccessToastProps {
  message: string;
}

export function SuccessToast({ message }: SuccessToastProps) {
  return (
    <div className="flex items-center p-2 gap-2 bg-white rounded-lg shadow-md">
      <div className="w-6 h-6 bg-green-400 rounded-lg text-center flex items-center justify-center">
        <IconCheck className="w-4 h-4" />
      </div>
      <p className="text-sm ">{message}</p>
    </div>
  );
}
