import { useOrgId } from 'hooks/useOrgId';
import { RuleFilter } from 'models/rules';
import { useState } from 'react';
import { useGetBlacklistQuery } from 'service/blacklist';
import Loader from 'components/Loader';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { EmptyRule } from './EmptyRule';
import { BlockRuleRow } from './BlockRuleRow';

interface RuleListProps {
  ruleType: RuleFilter | '';
  onAddRule: () => void;
}
export function BlockRuleList({ ruleType, onAddRule }: RuleListProps) {
  const columns = [
    {
      name: 'Email Address / Domain / IP',
      width: 'w-3/10',
    },
    {
      name: 'Date Added',
      width: 'w-1/5',
    },
    {
      name: 'Added By',
      width: 'w-1/5',
    },
    {
      name: 'Comments',
      width: 'w-1/5',
    },
    {
      name: 'Actions',
      width: 'w-1/10',
    },
  ];

  const [orgId] = useOrgId();

  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  });

  const { data, isLoading } = useGetBlacklistQuery({
    orgId,
    type: ruleType,
    limit: pagination.limit,
    offset: pagination.offset,
  });

  const getFallback = () => {
    if (!data?.hits?.length) {
      return (
        <td colSpan={columns.length}>
          <EmptyRule onAddRule={() => onAddRule()} />
        </td>
      );
    }

    if (isLoading) {
      return (
        <td colSpan={columns.length}>
          <div className="w-full flex items-center justify-center py-6">
            <Loader />
          </div>
        </td>
      );
    }

    return null;
  };

  return (
    <>
      <table className="w-full mt-8">
        <colgroup>
          {columns.map((header) => (
            <col key={header.name} className={header.width} />
          ))}
        </colgroup>
        <thead className="text-sm text-light-grey w-full border-y-light border-border-primary">
          <tr>
            {columns.map((column) => (
              <th
                key={column.name}
                scope="col"
                className="py-6 px-3 font-normal text-xs text-start text-light-grey"
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-full">
          {isLoading || !data?.hits?.length
            ? getFallback()
            : data?.hits?.map((rule) => (
                <BlockRuleRow
                  key={rule.entityId}
                  id={rule.id}
                  entity={rule.entity}
                  createdAt={rule.createdAt}
                  addedBy={rule.addedBy}
                  comment={rule.comment}
                  listType={rule.listType}
                  entityId={rule.entityId}
                />
              ))}
        </tbody>
      </table>
      {data?.hits?.length && !isLoading && (
        <div className="flex justify-between px-6 py-4 items-center">
          <span className="text-xs font-light">
            Showing {data && data?.hits?.length > 0 ? pagination.offset + 1 : '0'} -{' '}
            {data && data?.hits?.length > 0 ? pagination.offset + (data?.hits?.length || 0) : '0'}{' '}
            of {data?.total} events
          </span>
          <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
            <IconArrowDown
              className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${data && pagination?.offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (data) {
                  if (data.offset > 0) {
                    setPagination({
                      ...pagination,
                      offset: data.offset - data.limit,
                    });
                  }
                }
              }}
            />
            <IconArrowDown
              className={`w-6 h-6 -rotate-90 ${data && pagination.offset + pagination.limit < data.total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (data && pagination.offset + pagination.limit < data?.total) {
                  setPagination({
                    ...pagination,
                    offset: pagination.offset + pagination.limit,
                  });
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
