import { validateIPv4, validateDomains, validateEmail, ValidationResult } from 'utils/validation';

export function validateInput(input: string): ValidationResult {
  const ipResult = validateIPv4(input);
  if (ipResult.isValid) {
    return ipResult;
  }

  const emailResult = validateEmail(input);
  if (emailResult.isValid) {
    return emailResult;
  }

  const domainResult = validateDomains(input);
  if (domainResult.isValid) {
    return domainResult;
  }

  return { isValid: false, error: 'Input matches no valid format (email/ip/domain)' };
}
