import { ReactComponent as InfoBlockIcon } from 'assets/icons/add-block-list.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import { RuleFormInput } from 'components/Rules/RuleFormInput';
import { ListRecord } from 'models/rules';
import { useState } from 'react';
import { useCreateBlacklistMutation } from 'service/blacklist';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { validateInput } from './data';

interface BlockFormProps {
  onClose: () => void;
}
export function BlockRulesForm({ onClose }: BlockFormProps) {
  const [formInput, setFormInput] = useState<Array<ListRecord & { error?: string }>>([
    {
      value: '',
      comment: '',
      error: '',
    },
  ]);

  const { showToast } = useToast();

  const handleAdd = (index: number): void => {
    const newInput: ListRecord & { error: string } = { value: '', comment: '', error: '' };
    const updatedInputs = [
      ...formInput.slice(0, index + 1),
      newInput,
      ...formInput.slice(index + 1),
    ];
    setFormInput(updatedInputs);
  };

  const handleClose = (index: number): void => {
    if (formInput.length === 1) return;

    const newInputs = formInput.filter((_, i) => i !== index);
    setFormInput(newInputs);
  };

  const handleChange = (index: number, key: string, value: string): void => {
    let newInputs: Array<ListRecord & { error?: string }> = [];
    if (key === 'value') {
      const { isValid, error } = validateInput(value);
      if (isValid) {
        newInputs = formInput.map((input, i) =>
          i === index ? { ...input, [key]: value, error: '' } : input
        );
      } else {
        newInputs = formInput.map((input, i) =>
          i === index ? { ...input, [key]: value, error } : input
        );
      }
    } else {
      newInputs = formInput.map((input, i) => (i === index ? { ...input, [key]: value } : input));
    }

    setFormInput(newInputs);
  };

  const [createBlacklist] = useCreateBlacklistMutation();

  const hasEmptyOrErrorValues = (): boolean => {
    return formInput.some((input) => !input.value.trim() || input.error);
  };

  const [orgId] = useOrgId();

  const handleSubmit = async () => {
    if (hasEmptyOrErrorValues()) {
      return;
    }

    const formValues: ListRecord[] = formInput.map((input) => ({
      value: input.value,
      comment: input.comment,
    }));

    try {
      await createBlacklist({
        listEntries: formValues,
        orgId,
      }).unwrap();

      showToast({
        component: (
          <SuccessToast message={`Successfully added ${formInput.length} blacklist rules`} />
        ),
      });

      onClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = error as FetchBaseQueryError;

      showToast({
        component: <ErrorToast message={(message.data as string) || 'Something went wrong'} />,
      });
    }
  };

  return (
    <>
      <div className="px-8 py-6 border-b-light border-b-border-primary flex items-center justify-between w-full">
        <div className="flex items-center gap-4">
          <div className="w-10 h-10 flex items-center justify-center gap-4 border-light border-border-primary rounded-md">
            <InfoBlockIcon className="w-5 h-5" />
          </div>
          <span className="text-xl">Add a new Block Rule</span>
        </div>
        <button
          type="button"
          className="w-8 h-8 text-center rounded-full border-light border-border-primary flex items-center justify-center"
          onClick={() => onClose()}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <IconClose className="w-3 h-3" />
        </button>
      </div>
      <div className="p-8 w-full flex flex-col gap-4 max-h-overview-modal overflow-hidden overflow-y-scroll no-scrollbar border-b-light border-border-primary">
        {formInput.map((input, index) => (
          <RuleFormInput
            onChange={handleChange}
            value={input}
            index={index}
            onAdd={handleAdd}
            onClose={handleClose}
          />
        ))}
        <div className="flex w-full items-center justify-center gap-2">
          <InfoIcon className="w-3.5 h-3.5 fill-light-grey" />
          <span className="text-light-grey italic text-sm">
            Any future mail from these accounts will be automatically Quarantined.
          </span>
        </div>
      </div>
      <div className="w-full py-5 px-6 flex items-center justify-between gap-4">
        <div className="flex-1">
          <span className="w-fit px-2 py-1 bg-soft-gray border-light border-border-primary rounded-md text-sm text-light-grey">
            {formInput.length} account(s) added
          </span>
        </div>
        <button
          type="button"
          className="flex-1 py-4 text-center text-light-grey border-light border-border-primary rounded-md text-base"
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`flex-1 py-4 text-center border-light text-white border-border-primary ${!hasEmptyOrErrorValues() ? 'bg-black cursor-pointer' : 'bg-black/15 cursor-not-allowed'} bg-black rounded-md text-base`}
          onClick={() => handleSubmit()}
        >
          Confirm and Submit
        </button>
      </div>
    </>
  );
}
