import * as Dialog from '@radix-ui/react-dialog';

import { Divider } from 'components/Divider';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import { useState } from 'react';
import { RuleFilter } from 'models/rules';

import { BlockRuleList } from './BlockRuleList';
import { BlockRulesForm } from './BlockRulesForm';
import { AllowRulesForm } from './AllowRulesForm';
import { AllowRuleList } from './AllowRuleList';

export function BlockAndAllowRules() {
  const [selectedTab, setSelectedTab] = useState(0);

  const filterOption = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Email Addresses',
      value: 'sender',
    },
    {
      label: 'Domains',
      value: 'domain',
    },
    {
      label: 'IP Addresses',
      value: 'ip',
    },
  ];

  const [selectedFilter, setSelectedFilter] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const handleToggle = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <>
      <div className="px-10 py-8 w-full">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-xl">Block and Allow Rules</h2>
          <div className="flex items-center gap-2">
            <InfoIcon className="w-3.5 h-3.5 fill-light-grey" />
            <span className="text-light-grey italic text-sm">
              Most changes take effect within a few minutes.
            </span>
          </div>
        </div>
        <Divider customCss="my-8" />
        <div className="w-fit rounded-md border-light border-border-primary p-1 bg-soft-gray">
          <button
            type="button"
            className={`min-w-24 py-2.5 ${selectedTab === 0 ? 'text-critical-red border-light bg-white border-border-primary rounded-md text-center' : 'text-light-grey'}`}
            onClick={() => setSelectedTab(0)}
          >
            Block
          </button>
          <button
            type="button"
            className={`min-w-24 py-2.5 ${selectedTab === 1 ? 'text-success-green border-light bg-white border-border-primary rounded-md text-center' : 'text-light-grey'}`}
            onClick={() => setSelectedTab(1)}
          >
            Allow
          </button>
        </div>
        <Divider customCss="my-8" />
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center gap-4">
            {filterOption.map((filter, index) => (
              <button
                key={filter.label}
                className={`${index === selectedFilter ? 'bg-black text-white' : 'bg-soft-gray text-black'} rounded-full text-center px-4 py-1.5 border-light border-border-primary`}
                type="button"
                onClick={() => setSelectedFilter(index)}
              >
                {filter.label}
              </button>
            ))}
          </div>
          <button
            type="button"
            className="px-4 py-2 text-white bg-black rounded-md text-sm"
            onClick={() => handleToggle()}
          >
            Add a {selectedTab === 0 ? 'Block' : 'Allow'} Rule +
          </button>
        </div>
        {selectedTab === 0 ? (
          <BlockRuleList
            onAddRule={() => setShowModal(true)}
            ruleType={filterOption[selectedFilter].value as RuleFilter | ''}
          />
        ) : (
          <AllowRuleList
            onAddRule={() => setShowModal(true)}
            ruleType={filterOption[selectedFilter].value as RuleFilter | ''}
          />
        )}
      </div>
      <Dialog.Root open={showModal} onOpenChange={handleToggle}>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 z-20" onClick={handleToggle} />
        <Dialog.Content className="fixed top-1/2 left-1/2 flex flex-col justify-center items-center transform -translate-x-1/2 -translate-y-1/2 w-1/3 bg-white border-light border-border-primary rounded-md z-20">
          {selectedTab === 0 ? (
            <BlockRulesForm onClose={() => setShowModal(false)} />
          ) : (
            <AllowRulesForm onClose={() => setShowModal(false)} />
          )}
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
